import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Article from "../components/PageComponents/Article/Wisdom4.js"
import localize from "../components/utils/localize.js"

const IndexPage = ({ data, location, pageContext }) => (
  <Layout location={location} context={pageContext}>
    <SEO title="Drawing A BMW: How to Sketch Your Dream Car. 如何繪畫出您的BMW夢想汽車。" />
    <Article sanity={data} />
  </Layout>
)

export default localize(IndexPage)

export const pageQuery = graphql`
  query Article10Query {
    img1: file(name: { eq: "PORTAIT" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img2: file(name: { eq: "RIGHT" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img3: file(name: { eq: "LEFT" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img4: file(name: { eq: "BMW-Design-Sketch" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    article1: file(name: { eq: "article-1" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    article3: file(name: { eq: "article-3" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    article4: file(name: { eq: "article-4" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    article5: file(name: { eq: "article-5" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    article6: file(name: { eq: "article-6" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    article7: file(name: { eq: "article-7" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    article8: file(name: { eq: "article-cta" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sanityHomePage {
      exploreSectionTitle {
        _type
        en
        tc
      }
      exploreSectionParagraph {
        _type
        en
        tc
      }
      formTitle {
        _type
        en
        tc
      }
      howToJoin {
        _type
        en
        tc
      }
      howToJoinStepOne {
        _type
        en
        tc
      }
      howToJoinStepThree {
        _type
        tc
        en
      }
      howToJoinStepTwo {
        _type
        en
        tc
      }
      joinSectionParagraph {
        _type
        en
        tc
      }
      joinSectionTitle {
        _type
        en
        tc
      }
      submit {
        _type
        en
        tc
      }
      adventureTitle {
        _type
        en
        tc
      }
      embrace {
        _type
        en
        tc
      }
      freedomTitle {
        _type
        en
        tc
      }
      independenceTitle {
        _type
        en
        tc
      }
      inspirationTitle {
        _type
        en
        tc
      }
      progressTitle {
        _type
        en
        tc
      }
      wisdomTitle {
        _type
        tc
        en
      }
    }
  }
`
