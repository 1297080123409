import React, { useRef, useEffect } from "react"
import styles from "./index.module.scss"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import { initVimeoPlayer } from "../../utils"
import Explore from "../Home/Explore"
import Join from "../Home/Join"
import loadStates from "../../../hocs/loadStates"
import CTABtn from "../../Button/CTA"

function Header({ isChinese }) {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayer({
        selector: video.current,
        desktopVid: "https://vimeo.com/451031014/141ce5948f",
        mobileVid: null,
      })
  }, [])

  return (
    <header>
      <div ref={video}></div>
      <div className="pt-20 xxl:pt-32 pb-32 xxl:pb-40">
        <div className="w-11/12 md:w-full pl-40 md:px-12 xxl:px-56">
          <h2 className="quotes md:text-6xl bold-font mb-10">
            {isChinese
              ? "如何繪畫出您的BMW夢想汽車"
              : "Drawing A BMW: How to Sketch Your Dream Car"}
          </h2>
          {isChinese ? (
            <p className="title md:text-4xl mb-20">
              逐步繪畫汽車草圖 –
              我們會為您揭示BMW汽車設計師的繪畫技巧，即使是初學者也可輕鬆地繪畫出汽車草圖，並可勾勒出他們的BMW夢想汽車！
            </p>
          ) : (
            <p className="title md:text-4xl mb-20">
              Car drawing step by step – we reveal the tips from BMW car
              designers, even a beginner can do an easy car sketch and draw
              their Dream BMW car!
            </p>
          )}
          {isChinese ? (
            <p className="large-text light-font">
              您僅需要筆和紙 –
              借助BMW汽車外型設計師的技巧，您也可繪製出近乎完美的草圖！我們的教學可讓您逐步繪畫新款BMW2系列的GranCoupé，同時您還可掌握如何繪畫出您心中的BMW夢想汽車！
              <br />
              <br />
              如何勾勒出一輛汽車草圖:
            </p>
          ) : (
            <p className="large-text light-font">
              All you need is pens and paper – with the tips from our BMW
              exterior designers, you can also create an almost perfect
              sketching! Our tutorial allows you to draw the new BMW 2 Series
              Gran Coupé step by step and you will have a sense of how to
              produce an amazing drawing of your dream BMW car!
              <br />
              <br />
              Tips on how to sketch a car:
            </p>
          )}
        </div>
      </div>
    </header>
  )
}

function SectionOne({ data, isChinese }) {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayer({
        selector: video.current,
        desktopVid: "https://vimeo.com/451031083/71f89495d5",
        mobileVid: null,
      })
  }, [])

  return (
    <section className="xxl:pt-32 md:pb-20">
      <div className="image-container w-8/12 md:w-full mb-40 md:mb-0">
        <div ref={video}></div>
      </div>
      <div className="flex md:flex-col px-40 md:px-0 xxl:pb-0 xxl:px-56">
        <div className="w-5/12 xxl:w-4/12 pr-20 xxl:pr-0 md:hidden">
          <div className="image-container mb-20 xxl:mb-40">
            <Img fluid={data.img1.childImageSharp.fluid} />
          </div>
        </div>
        <div className="w-7/12 md:w-full xxl:w-8/12 xxl:px-40 pt-20 xxl:pt-32">
          <div className="block mb-40 md:px-12">
            <h3 className="quotes md:text-5xl bold-font mb-12">
              {isChinese
                ? "“勾勒出由左往右的側視輪廓圖 – 永遠要遵循由前往後看的視角。”"
                : "“Sketch out the silhouette of the side view flowing from left to right – always go from front to rear.”"}
            </h3>
            {isChinese ? (
              <p className="text-4xl md:text-2xl light-font mb-8">
                — BMW外型設計師Anne Forscher
              </p>
            ) : (
              <p className="text-4xl md:text-2xl light-font mb-8">
                Anne Forschner
                <br />
                Exterior Designer, BMW
              </p>
            )}
          </div>
          <div className="image-container mb-20 hidden md:block">
            <Img fluid={data.img1.childImageSharp.fluid} />
          </div>
          <div className="block w-11/12 xxl:w-10/12 md:w-full md:px-12 md:text-center">
            <h3 className="quotes md:text-6xl bold-font mb-12 md:mt-32">
              {isChinese ? "1. 觀察" : "1. Observation"}
            </h3>
            {isChinese ? (
              <p className="large-text light-font mb-20">
                開始繪畫草圖前，我們應盡收集有關汽車的照片，細心觀察，並加深印象。我們可從汽車的側面開始。仔細觀察是非常重要的環節，我們可注意到汽車的外型比例如何因視角和距離而改變？
                燈光會如何投射到車輛上並改變視覺觀感？
                如何善用最佳視角使該輛汽車看起來更具活力？
              </p>
            ) : (
              <p className="large-text light-font mb-20">
                Before we start to do the sketch, we need to collect as many
                impressions as possible. We may start with a side view.
                Observation is important as we can notice how are they
                proportioned? How the lighting hits the vehicle? How perspective
                shows the best angel and the vehicle look the most dynamic?
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionTwo({ data, isChinese }) {
  return (
    <section className="xxl:mt-0 md:mt-0 flex flex-col">
      <div className="flex px-40 md:px-0 xxl:px-56 md:order-2">
        <div className="w-full pr-20 md:px-12 xxl:pr-0 my-40 md:my-20 xxl:pb-40">
          <div className="flex">
            <div className="w-10/12 md:w-full">
              <h3 className="quotes md:text-5xl bold-font mb-12">
                {isChinese
                  ? "“畫第一筆前，請仔細思考汽車的形狀和視覺角度。但請務必預留足夠的即興創作空間 – 完美的設計理念將會逐漸浮現出來。”"
                  : "“Before your first pen strokes, think about the shape and perspective of the car. But leave enough room for improvisation – the perfect idea will gradually emerge.”"}
              </h3>
              {isChinese ? (
                <p className="large-text light-font mb-8">
                  — BMW外型設計師Anders Thøgersen
                </p>
              ) : (
                <p className="large-text light-font mb-8">
                  Anders Thøgersen
                  <br />
                  Exterior Designer, BMW
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-40 md:order-1 md:flex-col">
        <div className="w-6/12 md:w-full md:pr-12">
          <div className={`image-container`}>
            <Img fluid={data.img3.childImageSharp.fluid} />
          </div>
        </div>
        <div className="w-6/12 md:w-full md:pl-12">
          <div className={`image-container ${styles.imageContainerUp}`}>
            <Img fluid={data.img2.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionThree({ isChinese }) {
  return (
    <section className="pt-32 md:pt-20 xxl:py-64">
      <div className="flex justify-center">
        <div className="w-8/12 xxl:w-7/12 md:w-full pl-40 md:px-12 xxl:px-56 mb-32 text-center">
          <h3 className="quotes md:text-6xl bold-font mb-12">
            {isChinese ? "2. 時間" : "2. Time"}
          </h3>
          {isChinese ? (
            <p className="large-text light-font mb-8">
              多花點時間在繪製草圖上，不要對第一張草圖定下期望。不要侷限自己的靈感，
              並要有勇氣突破自我。想像您的BMW夢想汽車會是什麼樣子：轎跑車？轎車？休閒越野車？多花點時間將一些直覺草圖畫在紙上以拿捏繪畫感覺。汽車的設計需要時間來完成。
            </p>
          ) : (
            <p className="large-text light-font mb-8">
              Take your time in drawing and do not set out expectation with the
              first car sketch. Be brace and do not limit yourself. Think of how
              your dream BMW car will look: A coupé? A sedan? A SUV? Spend more
              time to throw a few intuitive sketches down onto some paper and
              develop a feeling. Automotive design takes time to develop.
            </p>
          )}
        </div>
      </div>
    </section>
  )
}

function SectionFour({ isChinese }) {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayer({
        selector: video.current,
        desktopVid: "https://vimeo.com/451031049/8f25aed7dd",
        mobileVid: null,
      })
  }, [])
  return (
    <section className="pt-32 md:pt-20 xxl:py-64">
      <div ref={video}></div>
      <div className="flex justify-center mt-24 xxl:mt-40">
        <div className="w-8/12 xxl:w-7/12 md:w-full pl-40 md:px-12 xxl:px-56 mb-32 text-center">
          <h3 className="quotes md:text-6xl bold-font mb-12">
            {isChinese ? "3. 細節" : "3. Details"}
          </h3>
          {isChinese ? (
            <p className="large-text light-font mb-8">
              一旦勾勒出基本汽車形狀後，您即可在草紙上加入其他細節。下一步確定車頂結構、汽車樑柱和車窗玻璃。不要忘記頭燈、鬼面罩、車身線條、進氣口和輪圈等細節。
            </p>
          ) : (
            <p className="large-text light-font mb-8">
              Once you have sketched out the basic shape, you can go into detail
              in the car drawing. Define the roof structure, the columns and the
              window panes. Don’t forget to define and position details like
              lights, grilles, swage lines, air intakes and rims etc.
            </p>
          )}
        </div>
      </div>
    </section>
  )
}

function SectionFive({ isChinese }) {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayer({
        selector: video.current,
        desktopVid: "https://vimeo.com/451031077/a0449432fa",
        mobileVid: null,
      })
  }, [])
  return (
    <section className="pt-32 md:pt-20 xxl:py-64">
      <div ref={video}></div>
      <div className="pt-32 md:pt-20 xxl:pt-40">
        <div className="flex">
          <div className="w-8/12 xxl:w-7/12 md:w-full pl-40 md:px-12 xxl:px-56 md:text-center">
            <h3 className="quotes md:text-6xl bold-font mb-12">
              {isChinese ? "4. 明暗對比" : "4. Shading"}
            </h3>
            {isChinese ? (
              <p className="large-text light-font">
                加入明暗對比和確定光線投射角度對草圖設計極為重要。草圖上需要有多個亮區，光線會直接照射到亮區，最暗的部分應相對應畫在陰影區中。首先須確保留空所有面向上的表面，因該區是光線射入的區域。然後，您可以逐漸將所有其他區域填為淺灰色。面朝地面的區域，顏色應較深且筆劃更密。
              </p>
            ) : (
              <p className="large-text light-font">
                It is important to define the shading of the design and define
                the source of light. There will need to be multiple bright areas
                where the rays of that light source hit directly, with the
                darkest areas correspondingly in the shade. Make sure initially
                to leave all upward- facing surfaces free as these are the area
                onto which the light falls. You can then gradually hatch all
                other areas in light gray. The darker and denser when the areas
                are towards the ground.
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="py-40 xxl:py-56">
        <div className="flex justify-end pr-32 xxl:px-40 md:pr-12 md:mt-20 md:px-12">
          <div className="w-6/12 xxl:w-5/12 md:w-full">
            {isChinese ? (
              <h3 className="quotes md:text-5xl bold-font mb-12">
                小貼士:
                依照亮度級別分配為1到10的值，並根據車身不同部位區域劃分光照水平
              </h3>
             ) : (
              <h3 className="quotes quotes--sm md:text-5xl bold-font mb-12">
                Tip: Assign values from 1 to 10 depending on the degree of
                brightness and divide different parts of the car body
                accordingly to their respective light levels.
              </h3>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionSix({ isChinese, data }) {
  return (
    <section className="pt-32 md:pt-20 xxl:py-64">
      <Img fluid={data.img4.childImageSharp.fluid} />
      <div className="flex justify-center mt-24 xxl:mt-40">
        <div className="w-8/12 xxl:w-7/12 md:w-full pl-40 md:px-12 xxl:px-56 mb-32 text-center">
          <h3 className="quotes md:text-6xl bold-font mb-12">
            {isChinese ? "5. 上色" : "5. Color"}
          </h3>
          {isChinese ? (
            <p className="large-text light-font mb-8">
              給汽車草圖上色有畫龍點睛的功能。上色可賦予車輛動感，可使暗啞的金屬和材料反射出燦爛的光彩，並賦予車輛三維立體外觀。請記住，草圖中的汽車應永遠與四周環境互動。您亦可用色環作為協助上色的導引。
            </p>
          ) : (
            <p className="large-text light-font mb-8">
              The use of color lends the finishing touch to a sketch of a car.
              Color gives the vehicle emotion, makes metal and materials reflect
              and gives your vehicle a three- dimensional appearance. Keep in
              mind that a car will always interact with its surroundings. You
              may also use a color wheel as a guide to help you out.
            </p>
          )}
        </div>
      </div>
    </section>
  )
}

function Article({ sanity, lang: { locale } }) {
  const isChinese = locale === "tc"
  return (
    <div>
      <Header isChinese={isChinese} />
      <SectionOne isChinese={isChinese} data={sanity} />
      <SectionTwo isChinese={isChinese} data={sanity} />
      <SectionThree isChinese={isChinese} />
      <SectionFour isChinese={isChinese} />
      <SectionFive isChinese={isChinese} />
      <SectionSix isChinese={isChinese} data={sanity} />
      <Explore home={sanity.sanityHomePage} />
      <Join home={sanity.sanityHomePage} />
      <CTABtn />
    </div>
  )
}

export default loadStates(Article)
